import { Component, Input, OnInit } from '@angular/core';
import { Operators } from 'src/app/models/operator';

@Component({
  selector: 'app-view-qr',
  templateUrl: './view-qr.component.html',
  styleUrls: ['./view-qr.component.scss'],
})
export class ViewQrComponent implements OnInit {
  @Input() operator: Operators;

  constructor() { }

  ngOnInit() {}

}
