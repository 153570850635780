import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { TaxRate } from '../models/tax_rate';
import { TaxCode } from '../models/tax_code';

@Injectable({
  providedIn: 'root'
})
export class QuickbookService {
  public url: string;
  public tax_rate: TaxRate[];
  public tax_code: TaxCode[];

  constructor(
    private _http: HttpClient,
  ) { 
    this.url=environment.url;
  }

  openQuickbook(): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(this.url + '/public/oauth2request/?new=true', { headers });

  }
  setupQuickbook(agency, client_id, secret, callback, environment): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/public/oauth2request/?id=${agency}&client_id=${client_id}&secret=${secret}&callback=${callback}&environment=${environment}`);
 
  }
  resendOrder(id): Observable<any>{
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/qb/resend/?id=${id}`, { headers });

  }
  statusQuickbooks(agency): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(this.url + '/qb/test_connect/?id='+agency, { headers });

  }

  taxesServices(tax): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(this.url + '/qb/config/tax/?value='+tax, { headers });

  }
}
