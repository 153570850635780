import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { PagesComponent } from './pages/pages.component';

const routes: Routes = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        redirectTo: 'orders',
        pathMatch: 'full'
      },
      {
        path: 'orders',
        loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrdersPageModule)
      },
      {
        path: 'customers',
        loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersPageModule)
      },
      {
        path: 'new-order',
        loadChildren: () => import('./pages/new-order/new-order.module').then(m => m.NewOrderPageModule)
      },
      {
        path: 'order/:id',
        loadChildren: () => import('./pages/new-order/new-order.module').then(m => m.NewOrderPageModule)
      },
      {
        path: 'find-order',
        loadChildren: () => import('./pages/find-order/find-order.module').then( m => m.FindOrderPageModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./pages/config/users/users.module').then( m => m.UsersPageModule)
      },
      {
        path: 'templates',
        loadChildren: () => import('./pages/templates/templates.module').then( m => m.TemplatesPageModule)
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
      },
      {
        path: 'quickbook',
        loadChildren: () => import('./pages/quick-book/quick-book.module').then( m => m.QuickBookPageModule)
      },
      {
        path: 'routes',
        loadChildren: () => import('./pages/config/routes/routes.module').then( m => m.RoutesPageModule)
      },
      {
        path: 'operators',
        loadChildren: () => import('./pages/config/operators/operators.module').then( m => m.OperatorsPageModule)
      },
      {
        path: 'operator-report',
        loadChildren: () => import('./pages/operator-report/operator-report.module').then( m => m.OperatorReportPageModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/public/login/login.module').then(m => m.LoginPageModule)
  }


];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
