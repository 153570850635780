import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-header-component',
  templateUrl: './header-component.component.html',
  styleUrls: ['./header-component.component.scss'],
})
export class HeaderComponentComponent implements OnInit {
  @Input() title:string;

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit() {}
 

  getModal(){
    return this.modalController;
  }
}
