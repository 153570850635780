import { Component, Input, OnInit } from '@angular/core';
import * as  print from 'print-js'
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-production-label',
  templateUrl: './production-label.component.html',
  styleUrls: ['./production-label.component.scss'],
})
export class ProductionLabelComponent implements OnInit {
  @Input() order: Order;
  public is_checked:boolean=false;


  constructor() { }

  ngOnInit() {
    if (this.order.pay_status==='PAID'){
      this.is_checked=true
    }else{
      this.is_checked=false
    }
  }

  onClick() {
    print({
       printable: 'contenido',
       type: 'html',
       font_size: '18px;',
       style: `
       @font-face{
        font-family: "roboto-light";
        src: url("../../../assets/font/Roboto-Light.ttf");
      }
      @font-face{
          font-family: "roboto-bold";
          src: url("../../../assets/font/Roboto-Bold.ttf");
      }
       table {
        width: 100% !important;
      }
      .header{
        text-align: center;
      }
      .label_title{
        border: 2px solid black;
        display: block;
        text-align: center;
        width: 90%;
        padding-top:10px;
        border-radius: 15px;
        font-weight: bold;
        text-transform: uppercase;
        margin:0 auto;
        margin-bottom: 10px;
        font-family: "roboto-bold";
      }
      .invoice{
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 90%;
        font-weight: bold;
        font-family: "roboto-bold";
      }
      label{
        display: block;
        text-align: center;
      }
      .date{
        display: block;
        text-align: center;
        width: 82%;
        font-size: 1.5em;
        margin-top:30px;
        font-family: "roboto-light";

      }
      .route{
        display: block;
        text-align: center;
        background-color: black;
        color:white;
        padding-top: 30px;
        width: 80%;
        border-radius: 50px;
        font-weight: bold;
        text-transform: uppercase;
        margin:0 auto;
        margin-bottom:0px;
        font-family: 'roboto-bold';
      }
      .largerCheckbox {
        margin:0 50%;
        text-align:center;
        margin-bottom: 10px;
        margin-top:10px;
        height:30px;
      }
      .product{
        font-family: 'roboto-light';
      }
      @page {
        size: 4in 6in;
        /* auto is the initial value */
        /* this affects the margin in the printer settings */
        margin: 10mm 10mm 10mm 10mm;
      }
      body {
        /* this affects the margin on the content before sending to printer */
        margin: 0px;
      }`
    });

  }

}
