import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  

  constructor(
    public toastController: ToastController,
    private alertController: AlertController
  ) { }

  async presentToast(msm,callBack) {
    const toast = await this.toastController.create({
      message: msm,
      duration: 2000,
      position:'middle'
    });
    if (callBack!=null){
      toast.onDidDismiss().then(()=>callBack());
    }
    toast.present();
    
  }

  async presentToastError(msm, callBack) {
    const toast = await this.toastController.create({
      message: msm,
      duration: 2000,
      position:'middle',
      color:'danger'
    });
    if (callBack!=null){
      toast.onDidDismiss().then(()=>callBack());
    }
    
    toast.present();
  }

  async presentToastErrorWithOption(msm,callBack) {
    const toast = await this.toastController.create({
      animated: true,
      buttons: [
        {
          side: 'start',
          text: 'Ok',
          role: 'cancel',
          handler: () => {
           // this.eventService.publishEventOk('hicimos click')
          }
        }
      ],
      color: 'danger',
      cssClass: 'toast-danger',
      header: 'Error',
      keyboardClose: true,
      message: msm,
      mode:"md",
      position: 'middle',
      translucent: true
      
    });
    if (callBack!=null){
      toast.onDidDismiss().then(()=>callBack());
    }
    toast.present();
  }

  async presentToastWithOptions(msm,callBack, header='Confirm Option') {
    const toast = await this.toastController.create({
      header: header,
      message: msm,
      position: 'middle',
      buttons: [
        {
          side: 'start',
          text: 'Yes',
          handler: () => {
            this.toastController.dismiss(true)
          },
        },
        {
          side: 'start',
          text: 'No',
          handler: () => {
            this.toastController.dismiss(false)
          },
        },
      ],
    });
    toast.present();
    const { data } = await toast.onWillDismiss();
    if (callBack!=null){
      toast.onDidDismiss().then(()=>callBack(data));
    }
   
  }

  async presentAlert(msm) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: 'Alert',
      message: msm,
      buttons: ['OK']
    });

    await alert.present();
  }
 
 
}
