import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router'
import { User } from 'src/app/models/user';
// import { AuthenticationService } from '../../services/authentication.service';
// import { AgencyService } from '../../services/agency.service';
import { MenuController } from '@ionic/angular';
import { environment } from '../../../environments/environment';
import { VersionService } from 'src/app/services/version.service';
// import { EnvironmentService } from 'src/app/services/environment.service';
// import { EventService } from 'src/app/services/event.service';
import { ToastService } from 'src/app/services/toast.service';
import { AuthService } from 'src/app/services/auth.service';
import { Opts_Menu } from 'src/app/interfaces/interfaces';


@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  public selected_index: number;
  public selected_sub_index=0;
  public appPages: Opts_Menu[];
  public show:boolean=false;
  public is_selected:string;
  @Input() user:User;
  public agency:string;
  public menu_inicial_opcion: string;
  public logo:string;
  public menu_portal: Opts_Menu;
  public visible:boolean=false;
  public show_version=0;

  constructor(
    private router:Router,
    private authenticationService:AuthService,
    private cdRef: ChangeDetectorRef,
    private menu: MenuController,
    private versionService: VersionService,
    // private environmentService: EnvironmentService,
    // private eventService: EventService,
    private toastService: ToastService
  ) { 
    this.getMe();
    this.logo='../../assets/img/Step_Solution_Miami_Stairnose_dark.png'
  }
  ngOnInit() {
    this.show=false;
    this.menu_inicial_opcion=this.router.url;
    this.getMenu();
  }
  ionViewWillEnter(){
    
  }
  getMe(){
   this.user=this.authenticationService.user;
  }
 
  async getMenu(){
    if (this.user.menu){
      this.appPages= this.user.menu;
    }
  
    this.selected_index=this.appPages.findIndex(p=> p.url===this.menu_inicial_opcion);
    if (this.selected_index===-1){
      this.appPages.forEach((p, index)=>{
        if (p.url===''){
           this.selected_sub_index= p.sub_menu.findIndex(h=>h.url===this.menu_inicial_opcion);
           if (this.selected_sub_index!=-1){
             return this.selected_index=index}
        }
      })
    
      this.selected_sub_index=  this.appPages[this.selected_index]!=undefined && this.appPages[this.selected_index].sub_menu!=undefined?this.appPages[this.selected_index].sub_menu.findIndex(si=>si.url===this.menu_inicial_opcion):undefined;
    }
  }
  ngAfterViewChecked() {
    if (this.user!=this.authenticationService.user){
      this.user=this.authenticationService.user;
      if (this.user!=undefined)this.getMenu();
    }
    
    this.cdRef.detectChanges();
  }
  
  onRouter(p){
    if (p.url === '') {
     
      if (p.show===undefined || !p.show) {
         p.show=true;
        return 
      } else {
        this.is_selected = '';
        return p.show = false;
      }
    }
    this.selected_sub_index=null;
    this.menu.close('first');
    this.router.navigate([p.url])
    this.show=false;

  }
  logOut(){
    this.authenticationService.logOut();
    this.user=undefined;
  }
 
   getVersion(){

    return this.versionService;
  }

  getVersionDate(){
    // return Number(this.versionService.version_date);
  }

  showVersion(){
    // this.show_version++
    // if (this.environmentService.SHOW_VERSION===null  || this.environmentService.SHOW_VERSION===undefined){
    //   this.environmentService.findProperty("SHOW_VERSION").then(
    //     resp=>console.log(resp)
    //   )
    // }
    // if (this.show_version===this.environmentService.SHOW_VERSION){
    //   if (this.visible){
    //     this.visible=false;
    //     this.show_version=0;
    //   }else{
    //     this.visible=true;
    //     this.show_version=0;
    //   }
    // }
  }

 

}
