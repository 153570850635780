import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Operators } from '../models/operator';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public url: string;
  public routes:[];
  public order_status:[];
  public pay_status:[];
  public options_menu_order:string []
  public operators:Operators[]


  constructor(
    private _http: HttpClient
  ) { 
    this.url= environment.url;
  }

  putConfig(config): Observable<any>{
    const parans = JSON.stringify(config);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + '/config', parans, { headers }); 
   }

  getconfig(id:string):Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    // return this._http.get<any>(`../../assets/data/${id}.json`, { headers });
    return this._http.get<any>(this.url + '/config/?id='+id, { headers });
  }

  getMenu(rol){

    if (rol==='admin'|| rol==='seller'){
      return this.options_menu_order=['edit order status','edit order','reception label', 'production label','cancel order', 'invoice'];
    }
    if (rol==='warehouse'){
      return this.options_menu_order=['edit order status','production label'];
    }
    
  }

  
}
