import { Component, Input, OnInit } from '@angular/core';
import * as  print from 'print-js'
import { Order } from 'src/app/models/order';

@Component({
  selector: 'app-reception-label',
  templateUrl: './reception-label.component.html',
  styleUrls: ['./reception-label.component.scss'],
})
export class ReceptionLabelComponent implements OnInit {
  @Input() order: Order;


  constructor() { }

  ngOnInit() {}

  onClick() {
    print({
       printable: 'contenido',
       type: 'html',
      //  honorColor:true,
       font_size: '18px',
       style: `
       @font-face{
        font-family: "barcode";
        src: url("../../../assets/font/LibreBarcode128-Regular.ttf");
     }
       @font-face{
        font-family: "roboto-light";
        src: url("../../../assets/font/Roboto-Light.ttf");
      }
      @font-face{
          font-family: "roboto-bold";
          src: url("../../../assets/font/Roboto-Bold.ttf");
      }
      .qr{
        max-width: 100px;
        text-align: center;
    }
      
     .barcode{
      text-align: center;
      font-family: 'barcode';
      font-size: 2em;
      }  
      table {
        width: 100%
      }
      .label_title{
        display: block;
        text-align: center;
        background-color: black;
        color: white;
        padding-top: 30px;
        width: 100%;
        border-radius: 50px;
        font-weight: bolder;
        text-transform: uppercase;
        margin: 0 auto;
        margin-bottom:10px;
        font-family: "Arial";
      }
      .product{
        font-family: 'Arial';
        padding-top: 5px;
      }
      .label_route{
        display: block;
        text-align: center;
        background-color: black;
        color: white;
        padding-top: 20px;
        padding-bottom: 0px;
        width: 100%;
        border-radius: 15px;
        font-weight: bold;
        text-transform: uppercase;
        margin: 0 auto;
        font-family: "Arial";
      }
      .invoice{
        display: block;
        margin: 0 auto;
        text-align: center;
        width: 90%;
        font-weight: bold;
        font-family: "Arial";
      }
      label{
        display: block;
        text-align: center;
      }
      .textarea{
        border: 2px solid black;
        border-radius: 50px;
      }
      .date{
        display: block;
        text-align: center;
        width: 100%;
        font-family: "Arial";
        margin-top: 0px;
      }
      p{
        margin-left: 30px;
        font-family: "Arial";
      }
      @page {
        size: 4in 6in;
        /* auto is the initial value */
        /* this affects the margin in the printer settings */
        margin: 10mm 10mm 10mm 10mm;
      }
      body {
        /* this affects the margin on the content before sending to printer */
        margin: 0px;
      }`
    });

  }


}



