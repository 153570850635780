import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'q'
})
export class QPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): any {
    const reqs = value.split('|');
    const cuenta= reqs[0].toUpperCase();
    const name= reqs[1].toLowerCase().replace(/\b[a-z]/g, function(letter) {
      return letter.toUpperCase();
    });
    const res= cuenta +' |'+ name +'|'+ reqs[2] + ' |' + reqs[3];
    return res;
  }

}
