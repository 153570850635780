import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { User } from 'src/app/models/user';
import { ModalController, ToastController } from '@ionic/angular';
import { OrderService } from '../../services/order.service';
import { Order } from 'src/app/models/order';
import { ConfigService } from 'src/app/services/config.service';
import { Client } from 'src/app/models/client';
import { ClientService } from 'src/app/services/client.service';


@Component({
  selector: 'app-from-to-report',
  templateUrl: './from-to-report.component.html',
  styleUrls: ['./from-to-report.component.scss'],
})
export class FromToReportComponent {
  @Input() is_report: boolean = false;
  @Input() update: boolean = false;
  @Output() sendTotals = new EventEmitter();
  @Output() sendOrders = new EventEmitter();

  @Input() user: User;
  public from: number;
  public to: number;
  public today: string;
  public is_valid: boolean = false;
  public min: string;
  public orders: Order[];
  public from_value: string;
  @Input() first_request: boolean;
  public show_filter:boolean=false;
  public status_pay:string='ALL';
  public ALL:string='ALL';
  public status_order="ALL";
  public route:string='ALL';
  public routes: [];
  public pay_status: [];
  public order_status: [];
  public client: Client;
  public visible: boolean=false;
  public place_holder: string= 'Search Customer';
  public po_job_selected: string="";






  constructor(
    public toastController: ToastController,
    private orderService: OrderService,
    public modalController: ModalController,
    private configService: ConfigService,
    private clientService: ClientService
 ) {
    this.getDates();
   

   
  }

  async ngOnChanges(changes: SimpleChanges) {

   this.getDates();
   this.getPayStatus();
   this.getStatusOption();
   this.getRouteOption();
  }

  getRouteOption(){
    if (this.configService.routes===undefined || this.configService.routes===null || !this.configService.routes.length){
      this.configService.getconfig("route").subscribe(
        resp=>{
          this.routes=resp.value;
          this.configService.routes=this.routes;
        }
      )
    }else{
      this.routes= this.configService.routes;
    }
    
  }

  getPayStatus(){
    if (this.configService.pay_status===undefined || this.configService.pay_status===null || !this.configService.pay_status.length){
      this.configService.getconfig("pay_status").subscribe(
        resp=>{
          this.pay_status=resp.value;
          this.configService.pay_status=this.pay_status;
        }
      )
    }else{
      this.pay_status=this.configService.pay_status;
    }
   
  }

  getStatusOption(){
    if (this.configService.order_status===undefined || this.configService.order_status===null || !this.configService.order_status.length){
      this.configService.getconfig("order_status").subscribe(
        resp=>{
          this.order_status=resp.value;
          this.configService.order_status=this.order_status;
        }
      )
    }else{
      this.order_status= this.configService.order_status;
    }
   
  }


  getDates() {
    const f = new Date();
    const day = f.getDate() < 10 ? `0${f.getDate()}` : f.getDate();
    const month = f.getMonth() + 1;
    const month_format = month < 10 ? `0${month}` : month;
    this.today = `${f.getFullYear()}-${month_format}-${day}`;
    this.from_value = this.today;
    this.min = this.today;
    const date_from = new Date(f.getFullYear(), f.getMonth(), f.getDate());
    this.from = date_from.getTime();
    const date_to = new Date(f.getFullYear(), f.getMonth(), f.getDate(), 23, 59, 59, 59);
    this.to = date_to.getTime();
    if (this.user != undefined) {
      if (this.first_request) {
        this.onSubmit();
        this.first_request = false;
      }
    }
  }

 
  async presentToastError(msm) {
    const toast = await this.toastController.create({
      message: msm,
      duration: 2000,
      position: "middle",
      color: "danger"
    });
    toast.present();
  }
  async presentToast(msm) {
    const toast = await this.toastController.create({
      message: msm,
      duration: 2000,
      position: "middle",
    });
    toast.present();
  }


  onDateFrom(event) {
    const date = event.detail.value;
    const datesplit = date.split('-');
    const year = datesplit[0];
    const month = datesplit[1];
    const day = datesplit[2];
    this.min = `${year}-${month}-${day}`
    const select_from = new Date(`${year}/${month}/${day} 00:00:01`);
    this.from = select_from.getTime();
    if (this.from != undefined && this.to != undefined) this.is_valid = true;
  }
  onDateTo(event) {
    const date = event.detail.value;
    const datesplit = date.split('-');
    const year = datesplit[0];
    const month = datesplit[1];
    const day = datesplit[2];
    const select_to = new Date(`${year}/${month}/${day} 23:59:59`);
    this.to = select_to.getTime();
    if (this.from != undefined && this.to != undefined) this.is_valid = true;
  }

  onSubmit() {
   
    if (!this.is_report) {
      this.orderService.getOrderFromTo(this.from, this.to).subscribe(
        resp => {
          this.orders = resp;

          if (this.client!=undefined){
            this.orders=this.orders.filter(p=>p.client.Id===this.client.Id);
            if (this.orders.length===0){
              setTimeout(()=>{
                if (this.orders.length===0){
                  this.client=undefined;
                  this.place_holder='Search Customer'
                }
              },1000); 
             
            }
          }
       
         
          if (this.status_pay!='ALL'){
            this.orders= this.orders.filter(p=>p.pay_status===this.status_pay);
          }

          if (this.status_order!='ALL'){
            this.orders= this.orders.filter(p=>p.status===this.status_order);
          }

          if (this.route!='ALL'){
            this.orders= this.orders.filter(p=>p.route===this.route);
          }
          if (this.po_job_selected!=''){
            this.orders= this.orders.filter(p=>p.po_job_name.toLowerCase()===this.po_job_selected.toLowerCase());
          }
          this.sendOrders.emit(this.orders);
        }
      )
   
    }
  }

  

  selectClient(item, is_edit = false, is = undefined) {
  
    if (item.id==='all'){
      this.client=undefined;
      this.place_holder='ALL'
    }else{
      this.client=item;
      this.visible = false;
      this.place_holder=this.client.DisplayName;
      // this.clientService.getClientById(item.id).subscribe(
      //   resp => {
      //     this.client = resp;
      //     this.visible = false;
      //     this.place_holder=this.client.name;
      //   }
      // )
    }
    
  }
 

}
