import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() option_popover:[];
  @Input() option_selected: string;

  constructor(
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

  onSelect(item){
    this.popoverController.dismiss(item);
  }

}
