import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Client } from '../models/client';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  public url: string;

  constructor(
    private _http: HttpClient,
  ) {
    this.url= environment.url;
   }

   getClients( ): Observable<any> {
     const headers = new HttpHeaders();
     return this._http.get<any>(`../../assets/data/customers.json`, { headers });
   }
   
   postClient(client: Client): Observable<any>{
    const parans = JSON.stringify(client);
    const headers = new HttpHeaders();
    return this._http.post(this.url + '/clients', parans, { headers }); 
   }
   updateClient(client: Client): Observable<any>{
    const parans = JSON.stringify(client);
    const headers = new HttpHeaders();
    return this._http.put(this.url + '/clients', parans, { headers }); 
   }
   getClientByParams(letters): Observable<any>{
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/customers/?params=${letters}`, { headers }); 

   }
   getClientById(id): Observable<Client>{
    const headers = new HttpHeaders();
    // return this._http.get<Client>(`${this.url}/clients?id=${id}`, { headers }); 
    return this._http.get<any>(`../../assets/data/customer.json`, { headers });
   }
   toAssignAccount(id): Observable<any>{
    const parans = JSON.stringify(id);
    const headers = new HttpHeaders();
    return this._http.put(this.url + '/clients/assign_account', parans, { headers }); 
   }
   saveClient(client: Client){
    const parans = JSON.stringify(client);
    const headers = new HttpHeaders();
    return this._http.post(this.url + '/public/client', parans, { headers }); 
   }

   getPaidAndPending(id:string):Observable<any>{
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/clients/statement?client_id=${id}`, { headers }); 
   }

  

}
