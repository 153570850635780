import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public url: string;
  public user: User;
  public token: string;
  public is_logged_in: boolean;
  public client: any;
  public count_request_error_401:number=0;


  constructor(
    private _http: HttpClient,
    private router: Router,
    private toastService: ToastService
  ) {
    this.url= environment.url;
    this.meSesion();
   }
  login(user): Observable<any> {
    const parans = JSON.stringify(user);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(`${this.url}/auth/login`, parans, { headers });
  }
  me():Observable<any>{
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(this.url + '/me', { headers });  

  }
  saveToken(token, refresh_token){
    this.token=token;
    localStorage.setItem('token', token);
    localStorage.setItem('refresh_token', refresh_token);
    this.count_request_error_401=0;
  }
  meSesion(){
    if (localStorage.getItem('token')){
      this.is_logged_in=true;
      this.token=localStorage.getItem('token');
    }
    if (localStorage.getItem('user')){
      this.user= JSON.parse(localStorage.getItem('user'));
      return 
    }
    this.is_logged_in=false;
    this.user=undefined;
    this.token='';

  }
 
  getToken() {
    if (this.token!=undefined ) {
      return this.token;
    }
    this.token = '';
  }

  logOut(){
    localStorage.removeItem('user');
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');

    this.token='';
    this.user=undefined;
    this.router.navigate(['/login']);
    this.client=undefined;
  }

  recoveryPassword(email): Observable<any> {
    const parans = JSON.stringify(email);
    const headers = new HttpHeaders();
    return this._http.post(this.url + '/public/client-password-recover', email, { headers });
  }

  changePassword(body):Observable<any>{
    const parans = JSON.stringify(body);
    const headers = new HttpHeaders();
    return this._http.put(this.url + '/users/update_password', parans, { headers });
  }

 
  updateUser(body):Observable<any>{
    const parans = JSON.stringify(body);
    const headers = new HttpHeaders();
    return this._http.put(this.url + '/users', parans, { headers });
  }
  postUser(body):Observable<any>{
    const parans = JSON.stringify(body);
    const headers = new HttpHeaders();
    return this._http.put(this.url + '/users', parans, { headers });
  }

  verifyRefreshToken(error){
    if (localStorage.getItem('token') && localStorage.getItem('refresh_token')){
      const verify_token={
        user:this.user.id,
        token: this.token,
        refresh_token: localStorage.getItem('refresh_token')
      }
      this.count_request_error_401++
      if (this.count_request_error_401===1){
        this.refreshToken(verify_token).subscribe(
          resp=>{
            this.saveToken(resp.token, resp.refresh_token);
            setTimeout(()=>{
            
              window.location.reload();
            },1000)
            
            
          }
        )
      }else{
        console.log('ignorar mensaje de error '+error)
        return
      }
    }else{
      if (this.router.url!='/login'){
        this.toastService.presentToastErrorWithOption(error,()=>{
          this.logOut();
          this.router.navigate(['/login']);
        })
      }
    }
  }

  refreshToken(verify_token): Observable<any> {
    const parans = JSON.stringify(verify_token);
    const headers = new HttpHeaders();
    return this._http.post(this.url + '/auth/refresh_token', parans, { headers });
  }
}
