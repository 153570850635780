import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Operators } from 'src/app/models/operator';
import { Order } from 'src/app/models/order';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-edit-status',
  templateUrl: './edit-status.component.html',
  styleUrls: ['./edit-status.component.scss'],
})
export class EditStatusComponent implements OnInit {
  @Input() order: Order;
  public order_status_options:string[];
  public intial_status: string;
  public initial_phone: string;
  public label: string;
  public operators: Operators[];


  constructor(
    private configService: ConfigService,
    private modalController: ModalController,
    private toastService: ToastService,
    private orderService: OrderService
    ) { }

  ngOnInit() {
    this.getOrder()
    if (this.configService.order_status===undefined || this.configService.order_status===null || !this.configService.order_status.length){
      this.configService.getconfig('order_status').subscribe(
        resp=>{
          this.order_status_options=resp.value.filter(p=>p!="new");
          this.configService.order_status= resp.value;
        }
      )
    }else{
      this.order_status_options=this.configService.order_status.filter(p=>p!="new");
    }
    this.getOperators();

   
    
  }
  getOrder(){
    this.orderService.getOrderById(this.order.id).subscribe(
      resp=>{
        this.order=resp;
        if (this.order && this.order.client && (this.order.client.phone_text_sms===undefined || this.order.client.phone_text_sms==='' || this.order.client.phone_text_sms===null)){
          this.order.client.phone_text_sms = this.order.client.PrimaryPhone.FreeFormNumber?this.order.client.PrimaryPhone.FreeFormNumber:"";
        }
       
        this.initial_phone=this.order.client.phone_text_sms;
        this.intial_status=this.order.status;
        this.order.send_sms=false;
      }
    )
  }
  getModalController(){
    return this.modalController;
  }

  onSendOrder(){
    if (this.order.status && this.order.status.toLowerCase()==='ready' || this.order.status.toLowerCase()==='pick up'){
      if (this.order.operator===undefined)return this.toastService.presentAlert(`Operator required for status ${this.order.status}.`);
    }
    if (this.intial_status!=this.order.status || this.initial_phone!= this.order.client.phone_text_sms ){
      let msm= this.order.send_sms && this.order.status==='pick up'?`Change status to the order and send SMS to ${this.order.client.phone_text_sms}?`:'Change status to the order?';
      this.toastService.presentToastWithOptions(msm,(p)=>{
        if (p){
          this.orderService.updateOrderStatus(this.order).subscribe(
            resp=>{this.modalController.dismiss(this.order);})
      }
    })
  }
  }

  changeStatus(){
    if (this.order.status==='pick up'){
      this.order.send_sms=true;
    }else{
      this.order.send_sms=false;

    }
  }

  onDeleteOperator(){
    this.toastService.presentToastWithOptions('Change operator?',(p)=>{
      if (p){
        this.order.operator=undefined;
        this.order.ready_operator_date=undefined;
        this.label=undefined;
        this.order.operator_name=undefined;
      }
    })
    
  }

  barCode(event){
    if (event.keyCode===13 || event.keyCode===10){
      setTimeout(() => {
           this.onSendBarCode();
         }, 500);
     }
  }
  
  onSendBarCode(){
    let operator: any;
    if (this.operators && this.operators.length){
      operator= this.operators.find(p=>p.id.toLowerCase()===this.label.toLowerCase());
      if (operator){
        const now = new Date();
        const milliseconds = now.getTime();
        this.order.operator=operator.id;
        this.order.ready_operator_date=milliseconds;
        this.order.operator_name=operator.name;
        this.toastService.presentToast(`Operator ${operator.name} selected.`,null)
      }else{
        this.toastService.presentToast('Operator id not found.',null)
      }
    }else{
      this.getOperators();
      if (this.operators!=undefined){
        this.onSendBarCode();
      }
    }
  
  }
  getOperators(){
    if (this.configService.operators=== undefined || !this.configService.operators.length){
      this.configService.getconfig('operator').subscribe(
        resp => {
          if (resp!=null){
            this.operators = resp.value.filter(p=>p.deleted===false);
          }
        }
      )
    }else{
      this.operators=this.configService.operators;
    }
  }

}
