import { Component, Input, OnInit } from '@angular/core';
import { ModalController, PopoverController } from '@ionic/angular';
import { Client } from 'src/app/models/client';

@Component({
  selector: 'app-menu-order',
  templateUrl: './menu-order.component.html',
  styleUrls: ['./menu-order.component.scss'],
})
export class MenuOrderComponent implements OnInit {
  @Input() client: Client;

  constructor(
    private modalController: ModalController,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {}

 


  getPopoverControler(){

    return this.popoverController;
  }
}
