import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { OrderQB } from '../models/order-QB';
import { Params } from '@angular/router';
import { Order } from '../models/order';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  public url: string;
  public restricted_orders:string []=['CANCEL']

  constructor(private _http: HttpClient,
  ) {
    this.url = environment.url;
  }
  getOrders(): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(this.url + '/orders', { headers });
    // return this._http.get<any>(`../../assets/data/orders.json`, { headers });


  }
  getOrderById(id): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(this.url + '/orders/?id='+id, { headers });
    // return this._http.get<any>(`../../assets/data/order.json`, { headers });


  }
  getOrderFromTo(from:number,to:number): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/orders/by-date/?orders_from=${from}&orders_to=${to}`, { headers });
  }
  getOrderFromToOperator(from:number,to:number, operator: string): Observable<any> {
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/orders/by-date-by-operator/?orders_from=${from}&orders_to=${to}&operator=${operator}`, { headers });
  }

  postOrder(order:any): Observable<any>{
    const parans = JSON.stringify(order);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.post(this.url + '/orders', parans, { headers }); 
  }
  getOrderByClientId(id:string):Observable<any>{
    const headers = new HttpHeaders();
    return this._http.get(this.url + '/orders/client?client='+id, { headers }); 
  }
  updateOrder(order:Order): Observable<any>{
    const parans = JSON.stringify(order);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.put(this.url + '/orders', parans, { headers }); 
  }
 
  registerPaid(payment):Observable<any>{
    const parans = JSON.stringify(payment);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');;
    return this._http.put(this.url + '/orders/pay', parans, { headers }); 
  }

  updateOrderStatus(statusOrder): Observable<any> {
    const parans = JSON.stringify(statusOrder);
    const headers = new HttpHeaders().set('Content-Type', 'application/json');;
    return this._http.put(this.url + '/orders/update_status', parans, { headers });
  } 
  deleteOrder(data): Observable<any> {
    const parans = JSON.stringify(data);
    const headers = new HttpHeaders();
    return this._http.put(`${this.url}/orders/cancel`, parans,{ headers });
  } 

  resendOrder(arg, id): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');;
    return this._http.put(`${this.url}/orders/resend-email?type=${arg}&id=${id}`, { headers });
  }
  
  getBalancePay(id): Observable<any>{
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/orders/qb?id_order=${id}`, { headers });
  }

  findOrderInQuickbook(qb_id, id): Observable<any>{
    const headers = new HttpHeaders();
    return this._http.get(`${this.url}/orders/find/?qb_id=${qb_id}&order_id=${id}`, { headers });
  }
}
