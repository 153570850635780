import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
// import { AnalitycsServiceService } from './analitycs-service.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class EventService {


  constructor(
    private toastService:ToastService,
    // private analitycsServiceService: AnalitycsServiceService,
  ) {
    this.clearCallBack();
   
   }

  
    private toastEvent = new Subject<any>();
    private callbacks:{
      status:number,
      callback:any,
      generateGA: boolean
    }[];

    callback401(error){
    }
    callbackUnExcpeted(error){
      const er = `${error.error.message != undefined ? error.error.message : error.message}`;
     
      this.toastService.presentToastErrorWithOption(er,null);
    }

    
    publishEventOk(data: any) {
        this.toastEvent.next(data);
    }

    getObservableEventOk(): Subject<any> {
        return this.toastEvent;
    }

    configureCallBack(status,callBack, generateGA){
      const index_callback=this.callbacks.findIndex(p=>p.status===status);
      if (index_callback!=-1){
        return this.callbacks[index_callback]={status:status,callback:callBack,"generateGA":generateGA}
      }else{
        this.callbacks.push({"status":status, "callback":callBack, "generateGA":generateGA});
        console.log(this.callbacks)
      }
    }
    clearCallBack(){
      this.callbacks=[];
      this.callbacks.push({"status":401, "callback":this.callback401, "generateGA":true})
    }
    executeCallBack(error){
      
      const callback = this.callbacks.find(p=> p.status=== error.status);
      console.log(callback)
      if (!callback)
      {
        const er = `${error.error.message != undefined ? error.error.message : error.message}`;
        // this.analitycsServiceService.trackError(error.status,er);
        this.callbackUnExcpeted(error)
      }
      else
      {
        // if (callback.generateGA){
        //   this.analitycsServiceService.trackError(error.status,error.error.message);
        // }
        callback.callback(error)
      }
     
    }
  

}
