import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { Order } from 'src/app/models/order';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';
import { QuickbookService } from 'src/app/services/quickbook.service';
import { ToastService } from 'src/app/services/toast.service';
import { EditStatusComponent } from '../edit-status/edit-status.component';
import { InvoiceComponent } from '../invoice/invoice.component';
import { PopoverComponent } from '../popover/popover.component';
import { ProductionLabelComponent } from '../production-label/production-label.component';
import { ReceptionLabelComponent } from '../reception-label/reception-label.component';

@Component({
  selector: 'app-order-info',
  templateUrl: './order-info.component.html',
  styleUrls: ['./order-info.component.scss'],
})
export class OrderInfoComponent implements OnInit {
  @Input() order:Order;
  public title:string="Order Info";
  public total_items:number;
  public is_create_order: boolean=false;
  public color: string;
  public user: User;



  constructor(
    private modalController: ModalController,
    private qbService: QuickbookService,
    private toastService: ToastService,
    private popoverController: PopoverController,
    private configService: ConfigService,
    private router: Router,
    private authService: AuthService,
    private orderService: OrderService,
    private alertController: AlertController
  ) { 
    if (this.authService.user!=undefined){
      this.user= this.authService.user;
    }else{
      this.getMe();
    }
    
  }

  ngOnInit() {
    
    this.total_items=this.order.items.reduce((acc,prod)=>acc+prod.total_item,0);
    if (this.order.pay_status!==undefined){
      if (this.order.pay_status==='PAID'){
        this.color='success'
      }else{
        this.color='danger'
      }
    }
    if (this.order.QB_ID && !this.order.cancelled && this.order.status!="deleted on QB"){
      console.log(this.order)
      this.orderService.findOrderInQuickbook(this.order.QB_ID, this.order.id).subscribe(
        resp=>{
          if (resp.new_status){
            this.toastService.presentToastErrorWithOption('Order deleted from quickbook. Order status will change to DELETE ON QB',()=>{
              this.order.status=resp.new_status;
            })
          }
        }
      )
    }
    
  }

  getMe(){
    this.authService.me().subscribe(
      resp=>{
        this.user=resp;
      }
    )
  }

  getModalController(){
    return this.modalController;
  }

  onRetry(item){
    this.toastService.presentToastWithOptions(`Error: ${item.QB_ERROR}`,(p)=>{
      if (p){
        this.qbService.resendOrder(item.id).subscribe(
          resp=>{
            this.toastService.presentToast('Orden Send.',null);
          }
        )
      }
    },'forward order to QuickBooks?')
   
  }
 
  onSendOrder(){
    this.modalController.dismiss(this.order);

  }

  async onMenu(ev:any){
    const popover = await this.popoverController.create({
      component: PopoverComponent,
      event: ev,
      translucent: false,
      componentProps:{
        option_popover: this.configService.getMenu(this.user.rol),
      }
      
    });
  
    await popover.present();
    const { data } = await popover.onWillDismiss();
    if (data!=undefined){
     if (data==='edit order status'){
        this.presentModalEditStatus(this.order);
     }
     if (data==='edit order'){
      this.router.navigate([`order/${this.order.id}`]);
      this.modalController.dismiss();
     }
     if (data==='reception label'){
      this.presentModalReceptionLabel(this.order)
     }
     if (data==='production label'){
      this.presentModalProductionLabel(this.order)
     }
     if (data==='cancel order'){
      this.deleteOrder(this.order)
     }
     if (data==='invoice'){
      this.presentModalInvoice(this.order)
     }
     
    }

  }

  async presentModalInvoice(item) {
    const modal = await this.modalController.create({
    component: InvoiceComponent,
    cssClass: "my-custom-modal-css",
    componentProps: { 
      order_to_report: item
     }
    });
  
    await modal.present();
  
    const data = await modal.onDidDismiss();
  
  }

  async deleteOrder(item: Order){
    let delete_order=false;
  
      const alert = await this.alertController.create({
        cssClass: 'my-custom-class',
        header: `Cancel Order ${item.id}?`,
        message: 'Observation:',
        inputs: [
          {
            name: 'observation',
            id: 'observation',
            type: 'text',
            placeholder: 'Observation'
          },
        ],
        buttons: [
          {
            text: 'YES',
            handler: (data) => {
              delete_order=true;
              this.alertController.dismiss(data);
                       }
          },
          {
            text: 'NO',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              delete_order=false;
              console.log('Confirm Cancel');
              
            }
          }
          
        ]
      });
  
     await alert.present();
     const { data } = await alert.onWillDismiss();
     console.log(delete_order)
     console.log(data)
     if (delete_order){
        let observation=data.observation;
        console.log(observation)
        item.cancelled=true;
        item.cancel_reason=observation;
        item.send_quickbook=false;
        item.status='CANCEL'
        this.orderService.updateOrder(item).subscribe(
          resp=>{
            this.toastService.presentToast('Order Canceled.',()=>{
              this.order.cancelled=true
            });
          } 
        ); 
     }
  
   }

  async presentModalReceptionLabel(item) {
    const modal = await this.modalController.create({
    component: ReceptionLabelComponent,
    cssClass: "my-custom-modal-label-css",
    componentProps: { 
      order: item
     }
    });
  
    await modal.present();
  
    const data = await modal.onDidDismiss();
  
  }

  async presentModalProductionLabel(item) {
    const modal = await this.modalController.create({
    component: ProductionLabelComponent,
    cssClass: "my-custom-modal-label-css",
    componentProps: { 
      order: item
     }
    });
  
    await modal.present();
  
    const data = await modal.onDidDismiss();
  
  }


  async presentModalEditStatus(item) {
    const modal = await this.modalController.create({
    component: EditStatusComponent,
    componentProps: { 
      order: JSON.parse(JSON.stringify(item)) 
    }
    });
  
    await modal.present();
  
    const data = await modal.onDidDismiss();
    if (data.data!= undefined){
      if (data.data!= undefined){
        item.status=data.data.status;
     
      }
      
      
    }
  
  }


  
}
