import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Operators } from 'src/app/models/operator';
import { Order } from 'src/app/models/order';
import { ConfigService } from 'src/app/services/config.service';
import { OrderService } from 'src/app/services/order.service';

@Component({
  selector: 'app-from-to-operator',
  templateUrl: './from-to-operator.component.html',
  styleUrls: ['./from-to-operator.component.scss'],
})
export class FromToOperatorComponent {
  @Output() sendOrders = new EventEmitter();

  public from: number;
  public to: number;
  public today: string;
  public is_valid: boolean = false;
  public min: string;
  public orders: Order[];
  public from_value: string;
  @Input() first_request: boolean;
  public operators: Operators[];
  public operator: string;





  constructor(
    private orderService: OrderService,
    public modalController: ModalController,
    private configService: ConfigService
 ) {
    this.getDates();
    this.getOperators();

   
  }

  async ngOnChanges(changes: SimpleChanges) {

   this.getDates();
   this.getOperators();
  }

  changeOperator(){
    if (this.from != undefined && this.to != undefined && this.operator!=undefined) this.is_valid = true;
  }

  getOperators(){
    if (this.configService.operators=== undefined || !this.configService.operators.length){
      this.configService.getconfig('operator').subscribe(
        resp => {
          if (resp!=null){
            this.operators = resp.value.filter(p=>p.deleted===false);
            if (this.operators){
              this.operators.sort(function (a, b) {
                var id_a = a.name.toUpperCase();
                var id_b = b.name.toUpperCase();
                if (id_a < id_b) {
                  return -1;
                }
                if (id_a > id_b) {
                  return 1;
                }
                return 0;
              });
            }
            
          }
        }
      )
    }else{
      this.operators=this.configService.operators;
    }
  }


  

  getDates() {
    const f = new Date();
    const day = f.getDate() < 10 ? `0${f.getDate()}` : f.getDate();
    const month = f.getMonth() + 1;
    const month_format = month < 10 ? `0${month}` : month;
    this.today = `${f.getFullYear()}-${month_format}-${day}`;
    this.from_value = this.today;
    this.min = this.today;
    const date_from = new Date(f.getFullYear(), f.getMonth(), f.getDate());
    this.from = date_from.getTime();
    const date_to = new Date(f.getFullYear(), f.getMonth(), f.getDate(), 23, 59, 59, 59);
    this.to = date_to.getTime();
      if (this.first_request) {
        this.onSubmit();
        this.first_request = false;
      }
  }

 
  


  onDateFrom(event) {
    const date = event.detail.value;
    const datesplit = date.split('-');
    const year = datesplit[0];
    const month = datesplit[1];
    const day = datesplit[2];
    this.min = `${year}-${month}-${day}`
    const select_from = new Date(`${year}/${month}/${day} 00:00:01`);
    this.from = select_from.getTime();
    if (this.from != undefined && this.to != undefined && this.operator!=undefined) this.is_valid = true;
  }
  onDateTo(event) {
    const date = event.detail.value;
    const datesplit = date.split('-');
    const year = datesplit[0];
    const month = datesplit[1];
    const day = datesplit[2];
    const select_to = new Date(`${year}/${month}/${day} 23:59:59`);
    this.to = select_to.getTime();
    if (this.from != undefined && this.to != undefined && this.operator!=undefined) this.is_valid = true;
  }

  onSubmit() {
   
    this.orderService.getOrderFromToOperator(this.from, this.to, this.operator).subscribe(
      resp => {
        this.orders = resp;
        this.sendOrders.emit(this.orders);
      }
    )
  }

  

}
