import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { Router } from '@angular/router';
import { EventService } from '../services/event.service';
import { ToastService } from '../services/toast.service';
import { AuthService } from '../services/auth.service';



@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private count_request:number=0;
  
  private is_first_request:boolean=true;

  constructor(
    private authService: AuthService,
    private router: Router,
    private loadingController: LoadingController,
    private eventService: EventService,
    private toastService: ToastService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    const re = /\tracking/gi;
    const lg = /login/gi;
    const qb =/public/gi;
    const rt =/refresh_token/gi;
    if (req.url.search(re)===-1) {
      if (req.url.search(lg)===-1){
        if (req.url.search(qb)===-1){
          if(req.url.search(rt)===-1){
            if (token){
              req = req.clone({
                setHeaders: {
                  //           'Api-Token': token,
                  'Authorization': 'Bearer ' + token
                  //           'X-Requested-With': 'XMLHttpRequest',
                }
              })
            }
          }
        }
      }
    }
    if (!this.count_request) {
      this.presentLoading();
    }

    this.count_request++;
  //  console.log(this.count_request);



    return next.handle(req).pipe(
      finalize(() => {
        this.count_request--;
        if (!this.count_request) {
          this.loadingController.getTop().then(v=>{
            if (v!=undefined){
              this.loadingController.dismiss();
            }
          })
        

        }
    
        if (this.count_request===0){
           
          this.loadingController.getTop().then(v=>{
            if (v!=undefined){
              this.loadingController.dismiss();
              // setTimeout(() => {
              //   this.loadingController.getTop().then(v=>{
              //     if (v!=undefined){
              //       this.loadingController.dismiss();
              //     }})
              // }, 3000);
            }
          })
           
        }
     
       
      
        
      })).pipe(

      catchError(error => {
        if (error.status===401 && error.error.message==="Signature has expired"){
            this.authService.verifyRefreshToken(error.error.message);
        }else{
          if (error.status===401){
            if (this.router.url!='/login'){
              this.toastService.presentToastErrorWithOption('Session Expired!!',()=>{
                this.authService.logOut();
              })
            }else{
              this.toastService.presentToastErrorWithOption(error.error.message,null)
            }
          }
        }
      
        this.eventService.executeCallBack(error);
        this.eventService.clearCallBack();
        throw new Error('');
      })
    )
  }
  
  async presentLoading() {
    const loading = await this.loadingController.create({
      message: 'Por favor espere...',
      spinner: 'crescent'
    });
    await loading.present();
  }



}
