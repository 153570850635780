import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { MenuComponent } from './menu/menu.component';
import { SimpleHeaderComponent } from './simple-header/simple-header.component';
import { SearchClientComponent } from './search-client/search-client.component';
import { QPipe } from '../pipes/q.pipe';
import { MenuOrderComponent } from './menu-order/menu-order.component';
import { OrderInfoComponent } from './order-info/order-info.component';
import { FromToReportComponent } from './from-to-report/from-to-report.component';
import { PopoverComponent } from './popover/popover.component';
import { EditStatusComponent } from './edit-status/edit-status.component';
import { HeaderComponentComponent } from './header-component/header-component.component';
import { ReportComponent } from './report/report.component';
import { ReceptionLabelComponent } from './reception-label/reception-label.component';
import { ProductionLabelComponent } from './production-label/production-label.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { FromToOperatorComponent } from './from-to-operator/from-to-operator.component';
import { OperatorReportComponent } from './operator-report/operator-report.component';
import { ViewQrComponent } from './view-qr/view-qr.component';






@NgModule({
  declarations: [
    MenuComponent,
    SimpleHeaderComponent,
    SearchClientComponent,
    QPipe,
    MenuOrderComponent,
    OrderInfoComponent,
    FromToReportComponent,
    PopoverComponent,
    EditStatusComponent,
    HeaderComponentComponent,
    ReportComponent,
    ReceptionLabelComponent,
    ProductionLabelComponent,
    InvoiceComponent,
    FromToOperatorComponent,
    OperatorReportComponent,
    ViewQrComponent
  ],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    FormsModule,
  ],
  exports: [
    MenuComponent,
    SimpleHeaderComponent,
    SearchClientComponent,
    QPipe,
    MenuOrderComponent,
    OrderInfoComponent,
    FromToReportComponent,
    PopoverComponent,
    EditStatusComponent,
    HeaderComponentComponent,
    ReportComponent,
    ReceptionLabelComponent,
    ProductionLabelComponent,
    InvoiceComponent,
    FromToOperatorComponent,
    OperatorReportComponent,
    ViewQrComponent
  ]
})
export class ComponentModule { }
