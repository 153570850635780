import { Component, Input, OnInit } from '@angular/core';
import { MenuController, PopoverController } from '@ionic/angular';
import { MenuOrderComponent } from '../menu-order/menu-order.component';


@Component({
  selector: 'app-simple-header',
  templateUrl: './simple-header.component.html',
  styleUrls: ['./simple-header.component.scss'],
})
export class SimpleHeaderComponent implements OnInit {
  @Input() title:string;
  @Input() disabled_menu:boolean=false;

  constructor(
    private menu: MenuController,
    private popoverController: PopoverController
  ) { }

  ngOnInit() {
  }
  onOpenMenu(){
    this.menu.open('first')

  }

  async presentPopover(ev: any) {
    
    const popover = await this.popoverController.create({
      component: MenuOrderComponent,
      cssClass: 'my-custom-class',
      event: ev,
      translucent: true,
      componentProps:{
        
      }
      
    });
    await popover.present();
    
    const { data } = await popover.onWillDismiss();
    if (data!=undefined){
      // this.orderService.getOrderById(this.order_selected.id).subscribe(
      //   resp=>{
      //     this.order_selected=resp;
      //     this.orders.map(p=>{
      //       if (p.id===this.order_selected.id){
      //         p.payment.status=this.order_selected.payment.status;
      //         p.last_status=this.order_selected.last_status;
      //       }
      //     }  
      //     )

      //   }
      // )
     
      
    }

  }

}
