import { Component, Input, OnInit } from '@angular/core';
import * as  print from 'print-js'
import { Order } from 'src/app/models/order';


@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  @Input() orders_to_report: Order[];
  public routes:string[];

  constructor() { }

  ngOnInit() {
    this.orders_to_report.forEach(p=>{
      p.products_quanty=p.items.reduce((acc,product)=>acc+product.qty,0 )
      if (this.routes===undefined){
        this.routes= new Array(p.route);
      }else{
        let new_route= this.routes.find(l=>l===p.route);
        console.log(new_route)
        if (new_route===undefined){
          console.log(new_route)
          this.routes.push(p.route)
        }
      }
    })
    console.log(this.routes)

  }

  onClick() {
    print({
       printable: 'contenido',
       type: 'html',
       style: `#content { 
        text-align: right; padding-right: 10px;
        page-break-after: initial; /* depreciating, use break-after */
        width:100%;
        page-break-inside: avoid;
    
       } 
    
       h2{
        color: rgba(102, 161, 230, 0.914); 
       }
       h3{
        color: rgb(180, 180, 180);
       }

    #content-center { 
        border: #262626 .5px solid;
        text-align: center;
        font-size: 12px;
       } 
     #content-left { 
        border: #262626 .5px solid;
        text-align: left;
        font-size: 12px;
    
       }  
    #content-header { 
        border: #262626 1px solid; 
        text-align: center;
       }      
    #content-header-packing-left { 
        text-align: left;
        color: #2596be; 
        background-color: #e1ecf2;
       }   
    #content-header-packing-center { 
        text-align: center;
        color: #2596be; 
        background-color: #e1ecf2;
       }   
    #content-header-packing-right { 
        text-align: right;
        color: #2596be; 
        background-color: #e1ecf2;
       }   
    #align-left{
        text-align: left;
    } 
    #align-center{
        text-align: center;
    } 
    #align-right{
        text-align: right;
    } 
    #thank{
      margin-top: 50px;
    }                      
    table { 
        width: 100%; 
        border-collapse: collapse;  
       } 
    #header{
        text-align:left;
        font-size:14px;
       }   
    #header> p{
        font-family: sans-serif;
        text-align:left;
        font-size: 12px;
       }
    tr { 
        height: 35px
       } 
    .number{
        border: #262626 .5px solid; 
        text-align: right;
        padding-right: 5px;
    }
    #contents{
        text-align:left !important;
    }
    .img_header {
        text-align:right;
    }  
    td>p{
        text-align: center;
    } 
    #contenido{
        page-break-after: always; /* depreciating, use break-after */
        break-after: page;
        width:100%;
        page-break-inside: avoid;
      }
    message{
      text-align: center;
      font-size: large;
    }  
  `
    });

  }


}
