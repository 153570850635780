import { Component, OnInit, Output, EventEmitter, ViewChild, Input } from '@angular/core';
import { Client } from 'src/app/models/client';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { ToastService } from 'src/app/services/toast.service';
import { EventService } from 'src/app/services/event.service';
import { ClientService } from 'src/app/services/client.service';


@Component({
  selector: 'app-search-client',
  templateUrl: './search-client.component.html',
  styleUrls: ['./search-client.component.scss'],
})
export class SearchClientComponent implements OnInit {
  @ViewChild('search') search;
  @Output('selected_client') selected_client= new EventEmitter;
  @Input() place_holder: string;
  @Input() is_orders: boolean=false;
  public client_data: Client[];
  public visible: boolean;
  public client_selected: boolean;
  public show_data: any[];
  public text_error_413: string;
  public search_value:string;
  public min_value_search:number;
  

  constructor(
    private clientService: ClientService,
    private toastService: ToastService,
    private eventService: EventService
  ) { 
    this.place_holder="Search Customer";
    this.min_value_search=environment.MIN_SEARCH;
  }

  ngOnInit() {
  
  }

 
  onSubmit(value){
    if (this.search_value.length < this.min_value_search) { this.visible = false; this.client_data=undefined; return};
    if (this.search_value.length >= this.min_value_search && !this.client_selected) {
      
  
     
        return this.getDataSearch(this.search_value);
           
    }
    this.client_selected = false;

  }
  getDataSearch(value: string) {
    this.eventService.configureCallBack(413,(error)=>{
      this.text_error_413='Many Records, should be more specific';
       setTimeout(() => {
         this.search.setFocus();
       }, 500);
    }, true);
    this.visible = true;
    this.clientService.getClientByParams(value).subscribe(
      resp=>{
        this.text_error_413=undefined;
        if (resp.message){
          return this.toastService.presentToastError(resp.message,null);
        }  
        this.client_data=resp;
        this.show_data=this.client_data;
        if (this.is_orders){
          this.show_data.push({id:'all', DisplayName:'ALL'});
        }
        if (Object.entries(this.show_data).length === 0) {
          this.visible = false;
          this.place_holder= "Not Data Found.";
          this.search_value=undefined;
        }
        this.eventService.clearCallBack();
      }
    )
    
    return this.show_data;
  }

  cancelFind() {
    this.visible = false;
    this.show_data=null;
  }
  isSelectClient(item){
    
    this.client_selected = true;
    this.visible = false;
    this.selected_client.emit(item);
    this.client_data=undefined;
    this.search_value=undefined;
    if (!this.is_orders){
      this.place_holder="Search Customer"
    }
    
  }

  clearClient(){
    this.client_selected = false;
    this.visible = false;
    this.selected_client.emit({id:'all', DisplayName:'ALL'});
    this.client_data=undefined;
    this.search_value=undefined;
    this.place_holder="Search Customer"
    
  }


 
}

